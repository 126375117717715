import React, { useEffect, useState } from "react";
import { ValidatorType } from "../../validators/ValidatorType";
import './css/sortableList.scss';

export interface SortableItem {
    id: number,
    name?: string,
    value?: string,
    displayName?: string,
    deleted?: boolean,
    sortOrder: number,
    icon?:string
    error?:boolean
    validator?:ValidatorType
}
interface SortableListProps {
    data: SortableItem[] | undefined,
    showRemove?: boolean,
    onItemSelection: (selectedItem: any) => void,
    onReOrder: (orderedList: SortableItem[] | any) => void,
    onRemove?: (itemRemoved: SortableItem | any, newList: SortableItem[] | any) => void,
    disableSort?:boolean
    errorIndexes?:number[]
}

export default function SortableList(props: SortableListProps) {
    const [data, setData] = useState(props.data);
    const [selectedItem, setSelectedItem] = useState<SortableItem>();
    const [showRemove, setShowRemove] = useState(props.showRemove);

    // hook setups
    useEffect(() => {
        setData(props.data);
    }, [props.data])

    useEffect(() => {
        setShowRemove(props.showRemove);
    }, [props.showRemove])

    const getDisplayText = (item: SortableItem): string => {
        return item.name || item.value || item.displayName || '';
    }


    const handleItemSelection = (event: any, selectedItem: any) => {
        props.onItemSelection(selectedItem);
        setSelectedItem(selectedItem);
    }

    const handleItemRemoved = (event: any, itemRemoved: any) => {
        event.stopPropagation();
        if (!data || (data?.length ?? 0) <= 0) {
            return;
        }
        if(!props.showRemove){
            const removeIndex = data.findIndex(f => f.id === itemRemoved.id);
            let newData: SortableItem[] = [...data.splice(removeIndex, 1)];
            setData(newData);
        }
        
        if (props.onRemove) {
            props.onRemove(itemRemoved, data);
        }
    }

    const handleDragStart = (e: any, item: SortableItem,index:number) => {
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.dropEffect = 'move';
        e.dataTransfer.clearData();
        e.dataTransfer.setData("id", item.id);
        e.dataTransfer.setData("index", index);

    }

    const handleDragging = (e: any) => {
        if (e.target) {
            let div = e.target as HTMLDivElement;
            div.classList.add('dragging');
        }
    }
    const handleDrop = (e: any, item: SortableItem, index:number) => {
        e.preventDefault();

        if (!data || (data?.length ?? 0) <= 0) {
            return;
        }
        const sourceId = parseInt(e.dataTransfer.getData("id"))
        const sourceIndex = parseInt(e.dataTransfer.getData("index"))
        const destinationIndex = data.findIndex(f => f.id === item.id)


        // let increaseTracker = 0;

        var o = data[sourceIndex]
        data[sourceIndex] = data[destinationIndex]
        data[destinationIndex] = o


        // data.forEach((f,i)=> f.sortOrder=i)

        let newOrder: SortableItem[] = data.map((item, index) => {

            return { ...item, sortOrder: index };

            // if (item.id === sourceId) {
            //     return { ...item, sortOrder: destinationIndex+1 };
            // }
            // if (index <= destinationIndex) {
            //     return { ...item, sortOrder: (index ) };
            // }
            // increaseTracker++;
            // return { ...item, sortOrder: destinationIndex + (1 * increaseTracker) };
        });

        setData(newOrder);

        props.onReOrder(newOrder);

        if (e.target) {
            let div = e.target as HTMLDivElement;
            let listItems = div.parentElement?.querySelectorAll('li');
            div.classList.remove('dragging');
            listItems?.forEach(li => {
                li.classList.remove('dragging', 'drag-over');
            });
        }

    }
    const handleDragOver = (e: any) => {
        e.preventDefault();
        if (e.target) {
            let div = e.target as HTMLDivElement;
            div.classList.add('drag-over');
        }
    }
    const handleDragLeave = (e: any) => {
        e.preventDefault()
        if (e.target) {
            let div = e.target as HTMLDivElement;
            div.classList.remove('drag-over');
        }
    }
    if (!data || (data?.length ?? 0) <= 0) {
        return <></>
    }

    return <>
        <ul className="sortable-list">
            {React.Children.toArray(data?.sort((a, b) => { return a.sortOrder - b.sortOrder; })?.map((item: SortableItem, index: number) => {
                return <>
                    <li draggable={props.disableSort ? false:true} onClick={(e) => { handleItemSelection(e, item) }} onDragStart={(e) => handleDragStart(e, item,index)} onDrag={handleDragging} onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={(e) => handleDrop(e, item, index)} className={`sortable-list--item sortable-list--item--bold ${(selectedItem?.id ?? 0) === item.id ? 'sortable-list--item--selected' : ''}${(showRemove && item.deleted ? ' deleted' : '')}`}>
                        {!props.disableSort && <i className="pi move pi-ellipsis-v"></i>}
                        {props.disableSort && item.icon && <><i className={`pi pi-${item.icon}`}></i> </>}
                        <span>{getDisplayText(item) + index.toString() + "___" + item.sortOrder || '* new item'}
                            {props.errorIndexes?.includes(index) && <span style={{color:'red'}}>*</span>}
                        </span>
                        
                        {showRemove && !item.deleted && <i onClick={(e) => { handleItemRemoved(e, item) }} className="pi pi-trash trash" title="Remove"></i>}
                    </li>
                </>
            }))}
        </ul>
    </>

}